import RAF from '../lib/raf'

import anime from 'animejs'
import getQuery from '../utils/getQuery'

let windowHeight = 0

const cuePoint = {
  start: 0.2591751797,
  end: 0.4892167991,
  passed: false
}

const hasCuePoints = getQuery('s', '1') == '2'

let _RAFID = 0

class ImgPlayer {

  constructor(opts) {
    _RAFID++

    this.canvas = null
    this.ctx = null

    this.isLoaded = false

    this.width = 0
    this.height = 0
    this.anchor = { x: 0, y: 0 }

    this.currentTime = 0
    this.framesLoaded = 0
    this.frames = []
    this.nbFrames = 0
    this.ratio = 1920 / 1080
    this.format = opts?.format || 'jpg'

    this.currentFrameIdx = 0

    this.scrollTgt = 0
    this.scroll = 0
    this.scrollEnd = 0
    this.offsetScroll = 0

    this.progress = 0

    this.isAutoPlaying = false

    this.initCanvas()

    this.update = this.update.bind(this)
    this.onWheel = this.onWheel.bind(this)
    this.autoScroll = this.autoScroll.bind(this)


    RAF.subscribe(`imgplayer${_RAFID}`, this.update)
    // this.addListeners()

  }

  load(path, nbFrames) {

    this.nbFrames = nbFrames

    return new Promise(async (resolve) => {

      for (let i = 0; i < nbFrames; i++) {
        const src = `${path}${this.format}/img${i}.${this.format}`

        const img = new Image
        img.onload = () => {
          this.framesLoaded++
          if (this.framesLoaded == nbFrames - 1) {
            resolve()
            this.onLoaded()
          }
        }
        img.src = src
        this.frames.push(img)

      }

    })

  }

  onLoaded() {
    this.isLoaded = true
  }

  initCanvas() {
    this.canvas = document.createElement('canvas')
    this.ctx = this.canvas.getContext('2d')
  }

  // addListeners() {
  //   this.addScrollListeners()
  // }

  // addScrollListeners() {
  //   if (!process.isClient) return
  //   window.addEventListener('wheel', this.onWheel)
  //   window.addEventListener('scroll', this.onWheel)
  // }

  // removeScrollListeners() {
  //   if (!process.isClient) return
  //   window.removeEventListener('wheel', this.onWheel)
  //   window.removeEventListener('scroll', this.onWheel)
  // }

  onWheel(currentScroll) {
    const progress = currentScroll / this.scrollEnd

    if (hasCuePoints && progress >= cuePoint.start && !cuePoint.passed && !this.isAutoPlaying) {
      this.waitForInteraction()
    }
    else {
      this.scrollTgt = currentScroll
    }

    if (progress < cuePoint.start) {
      cuePoint.passed = false
    }

    // from f118 - to f243

  }

  autoScroll(evt) {
    // if(evt.keyCode !== 32) return
    document.body.style.cursor = 'auto'

    const scrollTgt = this.scrollEnd * cuePoint.end

    anime({
      targets: this,
      scrollTgt: scrollTgt,
      duration: 4000,
      easing: 'linear',
      update: () => {
        document.documentElement.scrollTop = this.scrollTgt
      },
      complete: () => {
        this.isAutoPlaying = false
        this.addScrollListeners()
        cuePoint.passed = true
      }
    })

  }

  waitForInteraction() {

    this.isAutoPlaying = true
    this.removeScrollListeners()

    document.body.style.cursor = 'pointer'

    if (!process.isClient) return
    window.addEventListener('click', this.autoScroll, { once: true })

  }

  setProgress(progress) {
    this.progress = progress
  }

  update(dt) {
    // console.log({
    //   scrolltop: document.documentElement.scrollTop,
    //   scrollTgt: this.scrollTgt,
    //   currentFrameIdx: this.currentFrameIdx
    // })
    // console.log(document.documentElement.scrollTop)
    // window.requestAnimationFrame(this.update.bind(this))

    // this.currentTime += dt * .01//this.speed

    // const time = Math.max(0, this.currentTime)

    // this.currentFrameIdx = Math.min(this.nbFrames-1, Math.floor(Math.max(0, time)))

    // if (this.currentFrameIdx == this.nbFrames - 1) {
    //   this.currentTime = 0
    // }
    this.scroll += ((this.scrollTgt + this.offsetScroll) - this.scroll) * .1
    const p = this.progress//Math.max(0, Math.min(1, this.scroll / (this.scrollEnd - windowHeight)))
    this.currentFrameIdx = Math.floor((this.nbFrames - 1) * p)
    this.render()

    this.onUpdated(this.currentFrameIdx)

  }

  render() {
    if (!this.isLoaded) return

    const ctx = this.ctx
    const w = this.width
    const h = this.height

    // default offset is center
    const x = this.anchor.x * this.width
    const y = this.anchor.y * this.height
    const offsetX = 0.5
    const offsetY = 0.5

    const img = this.frames[this.currentFrameIdx]

    var iw = 1920,
      ih = 1080,
      r = Math.min(w / iw, h / ih),
      nw = iw * r,   // new prop. width
      nh = ih * r,   // new prop. height
      cx, cy, cw, ch, ar = 1;

    // decide which gap to fill    
    if (nw < w) ar = w / nw;
    if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh;  // updated
    nw *= ar;
    nh *= ar;

    // calc source rectangle
    cw = iw / (nw / w);
    ch = ih / (nh / h);

    cx = (iw - cw) * offsetX;
    cy = (ih - ch) * offsetY;

    // make sure source rectangle is valid
    if (cx < 0) cx = 0;
    if (cy < 0) cy = 0;
    if (cw > iw) cw = iw;
    if (ch > ih) ch = ih;

    // fill image in dest. rectangle
    ctx.clearRect(0, 0, w, h)
    ctx.drawImage(img, cx, cy, cw, ch, x, y, w, h);
  }

  resize(width, height) {
    this.width = width
    this.height = height

    this.canvas.style.width = width + 'px'
    this.canvas.style.height = height + 'px'
    this.canvas.width = width
    this.canvas.height = height

    // this.scrollEnd = document.documentElement.offsetHeight
    windowHeight = this.height
  }

  setScrollEnd(val) {
    this.scrollEnd = val
  }

  // call by parent
  onUpdated() { }

}

export default ImgPlayer