function getQuery(name, deflt) {

    const location = process.isClient ? window.location : { search: '' }

    let m, reg;
    if (deflt === undefined) {
        deflt = '';
    }

    reg = new RegExp("&?" + name + "=([^&]+)", 'g');
    m = reg.exec(location.search);
    if (m) {
        return m[1].replace(/%20|\+/g, " ");
    } else {
        return deflt;
    }

}

export default getQuery