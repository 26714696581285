<template>
    <header class="c-about-hero" data-scroll-section>
        <div class="c-about-hero_inner">
            <div class="o-container">
                <div class="o-grid -col-12 -gutter">
                    <div class="u-gc-1/13 u-gc-2/11@from-small u-gc-3/11@from-large">
                        <div v-if="title" class="o-padding -padding-small-top -padding-wide-bottom">
                            <h1
                                class="c-about-hero_title || c-heading-about -title"
                                v-html="title"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    name: "AboutHero",
    props: {
        title: {
            type: String,
            required: true,
        }
    }
};
</script>