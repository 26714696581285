<template>
  <article class="c-page -about" :class="{ mobile: isMobile }">
    <div class="img-player-container" ref="imgPlayerContainer"></div>
    <div class="o-scroll" ref="scroll">
      <div class="o-padding -padding-big-bottom">
        <template v-if="aboutData">
          <about-hero :title="aboutData.baseline" />
          <about-introduction :content="aboutData.biography" />
          <about-clients :content="aboutData.clients" />
          <about-contact :content="aboutData.contact" />
        </template>
      </div>
    </div>
  </article>
</template>

<script>
import AboutClientsVue from "../components/about/AboutClients.vue";
import AboutContactVue from "../components/about/AboutContact.vue";
import AboutHeroVue from "../components/about/AboutHero.vue";
import AboutIntroductionVue from "../components/about/AboutIntroduction.vue";

import locomotiveScrollMixin from "../mixins/locomotiveScrollMixin";
import cursorMixin from "../mixins/cursorMixin";

import sanity from "../client";
import { mapActions, mapState } from "vuex";
import { ABOUT_QUERY } from "../core/queries/aboutQuery";

import ImgPlayer from "../lib/imgPlayer";

import isMobile from "../utils/isMobile";

export default {
  name: "About",
  mixins: [locomotiveScrollMixin, cursorMixin],
  components: {
    "about-hero": AboutHeroVue,
    "about-introduction": AboutIntroductionVue,
    "about-clients": AboutClientsVue,
    "about-contact": AboutContactVue,
  },
  data() {
    return {
      isMobile: isMobile().phone || isMobile().tablet,
      aboutData: null,
    };
  },
  computed: {
    ...mapState("main", ["isPageEntering", "pageLeavingDuration"]),
    ...mapState("metrics", ["width", "height"]),
    ...mapState("scroll", ["currentScroll", "limitScroll"]),
  },

  beforeRouteLeave(to, from, next) {
    this.onPageLeaving(next);
  },
  watch: {
    width: "onResize",
    isPageEntering: "onPageEntering",
    currentScroll: "onCurrentScrollChange",
  },
  created() {
    console.log("about created");
    // No images to fetch

    // Fetch data
    this.fetchData();

    this.imgPlayer = null;
    if (!this.isMobile) {
      this.makeImgPlayer();
    }
  },
  methods: {
    ...mapActions("main", [
      "setImagesLoaded",
      "setDataLoaded",
      "forceScrollUpdate",
    ]),

    async fetchData() {
      // Fetch data
      await sanity.fetch(ABOUT_QUERY).then(
        (aboutData) => {
          this.aboutData = aboutData[0];

          this.$nextTick(() => {
            this.setDataLoaded(true);
            this.maxScroll = this.$refs.scroll.offsetHeight;
            if (this.isMobile) {
              this.setImagesLoaded(true);
            }
          });

          console.log(this.aboutData);
        },
        (error) => {
          console.error(error);
        }
      );
    },

    makeImgPlayer() {
      this.imgPlayer = new ImgPlayer({ format: "webp" });
      this.imgPlayer.anchor.x = 0;

      const path = `/img/sequences/about/`;
      this.imgPlayer.load(path, 262).then(this.onPlayerLoaded.bind(this));

      this.imgPlayer.setScrollEnd(document.body.offsetHeight);
    },

    onPlayerLoaded() {
      console.log("player loaded");
      this.$refs.imgPlayerContainer.appendChild(this.imgPlayer.canvas);

      this.$nextTick(() => {
        this.onResize();
        this.$nextTick(() => {
          this.forceScrollUpdate();
          this.setImagesLoaded(true);
        });
      });
    },

    onCurrentScrollChange() {
      if (this.isMobile) return;
      const p = this.currentScroll / this.limitScroll.y;
      this.imgPlayer.setProgress(p);
    },

    onPageEntering(isEntering) {
      if (!isEntering) return;
      console.log("ENTERING ANIMATION HERE");

      this.$nextTick(() => {
        this.forceScrollUpdate();
      });
    },

    onPageLeaving(next) {
      this.setImagesLoaded(false);
      this.setDataLoaded(false);

      // After animation complete
      setTimeout(() => {
        next();
      }, this.pageLeavingDuration);
    },

    onResize() {
      if (this.isMobile) return;
      this.imgPlayer.resize(this.width, this.height);
    },
  },
};
</script>


<style lang="scss" scoped>
.img-player-container {
  position: sticky;
  top: 0;
  left: 0;
}

.o-scroll {
  margin-top: -100vh;
  position: relative;
}

.mobile {
  .o-scroll {
    margin-top: 0;
    position: static;
  }
}
</style>
